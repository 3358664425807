<template>
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h5 class="card-text">
          {{ $t('commons.important_info') }}
        </h5>
      </div>
      <div class="card-body">
        <p class="card-text">Information about MIRACLE DEAL...</p>
      </div>
      <div class="card-footer text-center">
        <button @click="approve" class="btn btn-outline-primary text-bold-700 round mr-sm-1 mb-1 text-uppercase">
          {{ $t('commons.approve') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DealIntro',
  components: {},
  data() {
    return {};
  },
  methods: {
    approve() {
      window.localStorage.setItem('_dealInfoTextApproved', true);
      this.$router.push({ name: 'deal-intro' });
    }
  }
};
</script>
<style scoped>
.btn {
  border-radius: 2rem;
  padding: 1rem 3rem;
  font-size: 1rem;
}
.cardHover:hover {
  cursor: pointer;
  opacity: 0.7;
}
</style>
